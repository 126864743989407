import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';

const ContactPage: React.FC = () => {
	return (
		<Layout>
			<SEO title="Kontakt" />
			<Container>
				<p>Das Gästebuch und die Kommentare sind vorerst deaktiviert</p>
				<p>Wenn ihr mit uns in Verbindung treten wollte, schreibt uns gerne eine</p>
				<p>
					<a href="mailto:ruben@nina-ruben.de">Mail</a>
				</p>
			</Container>
		</Layout>
	);
};

export default ContactPage;
